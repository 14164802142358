class Feedback {

  constructor() {
    this.feedbackElement = document.querySelector('.feedback')
    this.openView, this.startView, this.formView, this.endView
    if (this.feedbackElement) {
      // Set views
      this.startView = this.feedbackElement.querySelector('[data-view="start"]')
      this.formView = this.feedbackElement.querySelector('[data-view="form"]')
      this.endView = this.feedbackElement.querySelector('[data-view="end"]')
      // Set start as default open view
      this.openView = this.startView
      this.observeSteps()
      this.countLetters()
    }

  }

  observeSteps() {
    this.feedbackElement.addEventListener('click', ev => {
      if (ev.target.value === "yes") {
        this.openView.classList.add('hidden')
        this.openView = this.endView
        this.openView.classList.remove('hidden')
      }
      if (ev.target.value === "no") {
        this.openView.classList.add('hidden')
        this.openView = this.formView
        this.openView.classList.remove('hidden')
      }
      if (ev.target.value === "send") {
        ev.preventDefault()
        this.sendForm()
        this.openView.classList.add('hidden')
        this.openView = this.endView
        this.openView.classList.remove('hidden')
      }
      if (ev.target.value === "cancel") {
        ev.preventDefault()
        this.openView.classList.add('hidden')
        this.openView = this.startView
        this.openView.classList.remove('hidden')
      }
    })
  }

  sendForm() {
    const form = this.feedbackElement.querySelector('form')
    const data = new FormData(form)
    fetch(form.action, {
      method: form.method,
      body: data,
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => {
      // Legg til error handling
      this.openView.classList.add('hidden')
      this.openView = this.endView
      this.openView.classList.remove('hidden')
      form.reset()
    })
  }

  countLetters() {
    const textareaElement = this.feedbackElement.querySelector('textarea')
    const lettercountElement = this.feedbackElement.querySelector('#lettercount')
    textareaElement.addEventListener('keyup', ev => {
      length = textareaElement.value.length
      lettercountElement.innerHTML = length
      if (length === textareaElement.maxLength) {
        lettercountElement.classList.add('max')
        return;
      }
      lettercountElement.classList.remove('max')
    })
  }

}

export default Feedback