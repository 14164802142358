class Navigation {

  constructor() {
    this.nav = document.querySelector('.topHeader');
    this.events();
  }

  events() {
    document.addEventListener('scroll', (e) => {
      if (window.scrollY >= 80) {
        this.nav.classList.add("nav-scrolled");
      }
      else {
        this.nav.classList.remove("nav-scrolled");
      }
    })

    document.addEventListener('click', ev => { // Submenu for Accessibility
      if (ev.target.classList.contains('navigation__button')) {
        let expanded = ev.target.parentElement.getAttribute('aria-expanded')
        if (expanded === "true") {
          ev.target.parentElement.setAttribute('aria-expanded', "false")
        } else {
          ev.target.parentElement.setAttribute('aria-expanded', "true")
        }
      }
    })
  }
}
export default Navigation;