class Accordion {
  
  constructor() {
    this.accordion = document.querySelector('.accordion');
    if (this.accordion) {
      this.events();
    }
  }

  events() {
    this.accordion.querySelectorAll('.ac-element__header')
      .forEach(element => {
      element.addEventListener('click', e => {
      
        let cname = 'accordion__element'
        let modifier = '--open'

        if (element.parentElement.className.endsWith(modifier)) {
          element.parentElement.className = cname;
        }
        else {
          element.parentElement.className = cname + modifier;
        }
      })
    })
  }
}
export default Accordion;  