class Map {
  
  constructor() {
    this.map = document.querySelector('.map');
    if (this.map) {
      this.events();
    }
  }

  events() {    
    let address = this.map.dataset.address;
    let geocoder;
    let map;
    function initMap() {
      geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': address }, function(results, status) {
        if (status == 'OK') {
          let mapOptions = {
            zoom: 15,
            center: results[0].geometry.location,
            mapTypeId: 'hybrid'
          };
          map = new google.maps.Map(document.getElementById("map"), mapOptions);
          let marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location
          });
        };
      });
    };
    initMap();
  }
}
export default Map;