import Accordion from './modules/Accordion';
import MobileMenu from './modules/MobileMenu';
import TimeLine from './modules/Timeline';
import Map from './modules/Map';
import Navigation from './modules/Navigation';
import Feedback from './modules/Feedback';


/* Instansier objekter */
const mobileMenu = new MobileMenu();
const timeline = new TimeLine();
const accordion = new Accordion();
const map = new Map();
const navigation = new Navigation();
const feedback = new Feedback();