class TimeLine {
  constructor() {
    this.timeline = document.querySelector('.content__grid');
    if (this.timeline) {
      this.events();
    }
  }

  events() {
    const elements = this.timeline.querySelectorAll('.content__element');
    this.timeline.addEventListener('scroll', e => {

      let center = this.timeline.getBoundingClientRect().width / 2;

      for (let i = 0; i < elements.length; i++) {
        let elementWidth = elements[i].getBoundingClientRect().width;
        let elementX = elements[i].getBoundingClientRect().x;
        let elementCenter = elementWidth / 2 + elementX;
        
        if (elementCenter < center - (elementWidth / 2)) {  
          if (elements[i].classList.contains('future')) {
            elements[i].classList.remove('future');
          }          
          elements[i].classList.add('past');          
        }
        else if (elementCenter > center + elementWidth) {
            if (elements[i].classList.contains('past')) {
              elements[i].classList.remove('past');
            }                 
            elements[i].classList.add('future');
          }
          else {
            if (elements[i].classList.contains('future')) {
              elements[i].classList.remove('future');
            }
            if (elements[i].classList.contains('past')) {
              elements[i].classList.remove('past');
            }          
          }
        }
    });

    const backBtn = document.querySelector('.buttons__back');
    const forwardBtn = document.querySelector('.buttons__forward');

    
    backBtn.addEventListener('click', e => {
      let w = this.timeline.getBoundingClientRect().right;
      let scrollLength = w / 2.5;
      
      if (w >= 2110) {
        scrollLength = w / 6;
      }
      
      this.timeline.scrollTo({
        left: (this.timeline.scrollLeft - scrollLength),
        behavior: 'smooth'
      })
    });
    
    forwardBtn.addEventListener('click', e => {
      let w = this.timeline.getBoundingClientRect().right;
      let scrollLength = w / 2.5;
      
      if (w >= 2110) {
        scrollLength = w / 6;
      }
      
      this.timeline.scrollTo({
        left: (this.timeline.scrollLeft + scrollLength),
        behavior: 'smooth'
      })
    });
  }
}

export default TimeLine;  